import UsuarioLogado from '../usuario-logado/usuario-logado';
import SessaoUsuarioContext from '../../contexts/SessaoUsuarioContext';
import useSignOut from '../../hooks/use-sign-out';
import LinksMovidesk from '../menu-movidesk/constante';

import { useContext, useMemo } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useToggleMenu } from './menuUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faChartSimple, faClockRotateLeft, faComments, faFileInvoiceDollar, faLaptopHouse, faPaperPlane, faPeopleGroup, faPersonWalkingDashedLineArrowRight, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { Modulos } from '../../helpers/constantes/modulo';
import { Comum } from '../../services/UsuarioService';
import './menu.css';

const Menu = () => {
  const { sessao } = useContext(SessaoUsuarioContext);
  const permiteAtualizarPaineis = useMemo(() => {
    return sessao?.modulosLiberados.some(modulo => modulo.nome.toLocaleLowerCase() === Modulos.Pessoal && modulo.status)
  }, [sessao]);

  const signOutCallback = useSignOut();
  const navigate = useNavigate();
  const { isOpen, toggleMenu } = useToggleMenu();

  const integracaoMovidesk = (value: string) => {
    toggleMenu()
    window.open(value.toString(), '_blank');
  }

  const menu =
    <div className="menu-container">
      <div className="hamburger" onClick={toggleMenu}>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
        <div className="hamburger-line"></div>
      </div>
      <ul className={`menu ${isOpen ? 'open' : ''}`}>
        <li className='menu-user-data'><UsuarioLogado /></li>
        {sessao?.temLicencaFortesPessoal ? <li><NavLink to='modulo/pessoal' onClick={toggleMenu}> <FontAwesomeIcon icon={faFileInvoiceDollar} /> Módulo Pessoal</NavLink></li>
          : <li><NavLink to='#' style={{ color: 'gray', cursor: 'default' }}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} /> Módulo Pessoal</NavLink></li>}
        {sessao?.temLicencaFortesRh ? <li><NavLink to='modulo/rh' onClick={toggleMenu}><FontAwesomeIcon icon={faPeopleGroup} /> Módulo RH</NavLink></li>
          : <li><NavLink to='#' style={{ color: 'gray', cursor: 'default' }}>
            <FontAwesomeIcon icon={faPeopleGroup} /> Módulo RH</NavLink></li>}
        {/* <li><NavLink to='modulo/financeiro' onClick={toggleMenu}><FontAwesomeIcon icon={faMoneyBillTransfer} /> Módulo Financeiro</NavLink></li> */}
        <hr className='divisao-fixa' />
        <li><NavLink to='/faturamento' onClick={toggleMenu}> <FontAwesomeIcon icon={faChartSimple} /> Preencher Faturamento</NavLink></li>
        { permiteAtualizarPaineis && <li><NavLink to='/atualizar' onClick={toggleMenu}><FontAwesomeIcon icon={faArrowsRotate} /> Atualizar Painéis</NavLink></li>}
        {
          sessao?.categoria !== Comum && <>
            <li><NavLink to='/usuario' onClick={toggleMenu}><FontAwesomeIcon icon={faUsers} /> Usuários</NavLink></li>
          </>
        }
        <li><NavLink to='/empresa' onClick={toggleMenu}><FontAwesomeIcon icon={faBuilding} /> Empresas</NavLink></li>
        <li><NavLink to='/historico' onClick={toggleMenu}> <FontAwesomeIcon icon={faClockRotateLeft} /> Histórico de Versões</NavLink></li>
        <hr className='divisao' />
        <li className='movidesk-menu-item'><NavLink to='' onClick={() => integracaoMovidesk(LinksMovidesk.CHAT_PESSOAL)}>
          <FontAwesomeIcon className='img' icon={faComments} /> Chat</NavLink></li>
        <li className='movidesk-menu-item'><NavLink to='' onClick={() => integracaoMovidesk(LinksMovidesk.CENTRA_DE_AJUDA)}>
          <FontAwesomeIcon className='img' icon={faLaptopHouse} /> Central de ajuda</NavLink></li>
        <li className='movidesk-menu-item'><NavLink to='' onClick={() => navigate('/ticket')}>
          <FontAwesomeIcon className='img' style={{ width: "23px" }} icon={faPaperPlane} /> Enviar Sugestão</NavLink></li>
        <hr className='divisao-fixa' />
        <li><NavLink to='/' onClick={signOutCallback}><FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} /> Sair</NavLink></li>
      </ul>
    </div>

  return sessao ? menu : <></>;
};

export default Menu;