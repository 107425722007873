import { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import AmbienteService from '../../services/AmbienteService';

import './AtualizarDados.css'

import SessaoUsuarioContext from '../../contexts/SessaoUsuarioContext';
import AppContext from '../../contexts/AppContext';
import Spinner from '../../components/spinner/spinner';
import Voltar from '../../components/voltar/voltar';
import useFeedback from '../../hooks/use-feedback';

const zipMimetypes = ['.zip', 'application/zip', 'application/x-zip-compressed'];

const AtualizarDados = () => {
  const { sessao } = useContext(SessaoUsuarioContext);
  const { estaAtualizando, setAtualizando } = useContext(AppContext);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [responseMessage, setResponseMessage] = useState<string | null>();
  const [estaEnviando, setEnviando] = useState(false);
  const { adicionarFeedbackDeErro, limparFeedbacks } = useFeedback();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length && zipMimetypes.some(mime => mime === acceptedFiles[0].type)) {
      const file = acceptedFiles[0];
      setSelectedFile(file);
    } else {
      console.log('Please upload only one zip file.');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const clearSelectedFileHandler = useCallback(() => {
    setResponseMessage(undefined);
    setSelectedFile(undefined);
  }, []);

  const sendSelectedFileHandler = useCallback(async () => {
    limparFeedbacks();
    if (sessao && selectedFile) {
      try {
        setEnviando(true);
        const response = await AmbienteService.atualizarAmbiente(selectedFile);
        setAtualizando(true);
        setResponseMessage(response.mensagem);
      } catch (error) {
        adicionarFeedbackDeErro((error as Error).message || error + '');
        console.error(error);
        setAtualizando(false);
      }
      setEnviando(false);
      clearSelectedFileHandler();
    }
  }, [selectedFile, sessao, setAtualizando, clearSelectedFileHandler, adicionarFeedbackDeErro, limparFeedbacks]);

  return (
    <>
      <h1 className="top-title"><Voltar />Atualizar Painéis</h1>
      <div className='formulario'>
        {
          estaAtualizando || estaEnviando
            ? <div className="card-dropzone">
              <Spinner size={170} />
              <p>Seu ambiente está sendo atualizado...</p><p>Por favor, aguarde.</p>
            </div>
            : <div className="card-dropzone">
              {
                selectedFile
                  ? <div className="selectionzone">
                    <label>Arquivo selecionado:</label><br />
                    <span>{selectedFile?.name}</span>
                    <button className='remover-button' onClick={clearSelectedFileHandler}>&#215;</button>
                    {
                      !responseMessage
                        ? <button className='submit-button' onClick={sendSelectedFileHandler} disabled={estaEnviando} title='Remover'>Enviar Arquivo</button>
                        : <div className='selectionzone-content'>{responseMessage}</div>
                    }
                  </div>
                  : <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    {
                      isDragActive
                        ? <p>Solte o arquivo .zip aqui!</p>
                        : <p>Arraste e solte o arquivo .zip aqui, ou clique para localizar o arquivo...</p>
                    }
                  </div>
              }
            </div>
        }
      </div>
    </>
  );
};

export default AtualizarDados;