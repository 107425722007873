import FBIHttp from "./FBIHttp";

interface ObtencaoDeTimestampOutput {
  timestamp: number,
}

class TimestampService {
  public static obterTimestamp = async (): Promise<ObtencaoDeTimestampOutput> => FBIHttp.get('/api/publico/timestamp');
}

export default TimestampService;