export const HoraInicioDisponibilidade = 7
export const HoraFinalDisponibilidadeDiaUtil = 21
export const HoraFinalDisponibilidadeSabado = 17

export const MinutoOuSegundoZero = 0
export const MinutoFinalDisponibilidade = 50
export const MinutosAntesParaCronometro = 50

export const DivideMiliSegundos = 1000
export const DivideSegundosTotais = 60
export const PercentualSegundosTotais = 60 
export const SegundosDoCronometro = 59

export const Sabado = 6
export const Domingo = 7
