import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Ambiente } from '../models/Ambiente';
import SessaoUsuarioContext from '../contexts/SessaoUsuarioContext';
import AppContext from '../contexts/AppContext';

import AmbienteService from '../services/AmbienteService';
import UsuarioService, { UsuarioDoLicenciadoOutput } from '../services/UsuarioService';
import UsuarioLocalStorageService from '../services/UsuarioLocalStorageService';

import Menu from '../components/menu/menu'
import Spinner from '../components/spinner/spinner';
import Feedback from '../components/feedback/feedback';
import UsuarioLogado from '../components/usuario-logado/usuario-logado';

import Faturamento from '../pages/faturamento/Faturamento';
import AtualizarDados from '../pages/atualizar-dados/AtualizarDados';
import Painel from '../pages/painel/Painel';
import SelecaoDeLicenciado from '../pages/licenciado/selecao-de-licenciado';

import useSignOut from '../hooks/use-sign-out';
import useFeedback from '../hooks/use-feedback';
import { useToggleMenu } from '../components/menu/menuUtils';
import Historico from '../pages/historico/Historico';

import logoImg from './Logo.png';
import signOutImg from './signout.jpg';

import MenuMovidesk from '../components/menu-movidesk/botoes';
import Ticket from '../pages/ticket/ticket';
import TicketNovo from '../pages/ticket/ticketCriacao/ticketCriacao';
import ListaDePaineis from '../pages/lista-de-paineis/lista-de-paineis';
import Home from '../pages/home/Home';
import Usuario from '../pages/usuario/usuario';
import UsuarioCriacao from '../pages/usuario/usuario-criacao/usuario-criacao';
import Empresa from '../pages/empresa/empresa';
import EmpresaCriacao from '../pages/empresa/empresa-criacao/empresa-criacao';

import './App.css';
import '../pages/shared/content-container.css';
import ProtectedRoute from '../components/protectedRoute/protectedRoute';
import ContagemRegressiva from '../components/contagemRegressiva/contagemRegressiva';
import TimestampService from '../services/TimestampService';
import {
  Domingo,
  HoraFinalDisponibilidadeSabado,
  HoraInicioDisponibilidade,
  HoraFinalDisponibilidadeDiaUtil,
  MinutoFinalDisponibilidade,
  Sabado,
  MinutosAntesParaCronometro,
  MinutoOuSegundoZero
} from '../helpers/constantes/tempo';
import { DateTime } from 'luxon';

const App = () => {
  const navigate = useNavigate();
  const signOutCallback = useSignOut();
  const [sessao, setSessao] = useState<UsuarioDoLicenciadoOutput | null>();
  const [estaAtualizando, setAtualizando] = useState<boolean>(true);
  const [intervalAtualizando, setIntervalAtualizando] = useState<any>();
  const [ambiente, setAmbiente] = useState<Ambiente>({} as Ambiente);
  const [usuariosLicenciados, setUsuariosLicenciados] = useState<UsuarioDoLicenciadoOutput[]>([]);
  const { feedbacks, adicionarFeedbackDeErro, limparFeedbacks, removerFeedback } = useFeedback();
  const [adicionouTratamentoDeErro, setAdicionouTratamentoDeErro] = useState(false);
  const location = useLocation();
  const { isOpen = false, toggleMenu } = useToggleMenu();
  const ocorreuErro = useMemo(() => !!(ambiente.erro || "").trim(), [ambiente.erro]);

  const [bloqueio, setBloqueio] = useState<boolean>(false);
  const [exibirCronometro, setExibirCronometro] = useState<boolean>();
  const [tempoRestante, setTempoRestante] = useState<number>();
  const [horariosDeFuncionamento, setHorariosDeFuncionamento] = useState(
    {
      horaInicio: 0,
      horaFimDiaUtil: 0,
      horaFimSabado: 0
    });


  useEffect(limparFeedbacks, [location, limparFeedbacks]);

  const tratarErro = useCallback((eventoDeErro: any) => {
    eventoDeErro.preventDefault();
    limparFeedbacks();
    adicionarFeedbackDeErro((eventoDeErro.error || eventoDeErro.reason).message);
  }, [limparFeedbacks, adicionarFeedbackDeErro]);

  useEffect(() => {
    if (!adicionouTratamentoDeErro) {
      window.addEventListener("error", tratarErro);
      window.addEventListener("unhandledrejection", tratarErro);
      setAdicionouTratamentoDeErro(true);
    }
    return () => {
      window.removeEventListener("error", tratarErro);
      window.removeEventListener("unhandledrejection", tratarErro);
    }
  }, [adicionouTratamentoDeErro, setAdicionouTratamentoDeErro, tratarErro]);

  useEffect(() => {
    (async () => {
      if (!usuariosLicenciados.length) {
        const licenciados = await UsuarioService.obterInformacoesDoEmailDaSessao();
        if (!licenciados.length) {
          signOutCallback();
          localStorage.setItem("acesso-negado", "Você não tem acesso a nenhum licenciado.");
          return;
        }
        setUsuariosLicenciados(licenciados);
      }
    })();
  }, [usuariosLicenciados, sessao, signOutCallback]);

  useEffect(() => {
    if (!sessao && usuariosLicenciados.length) {
      const usuarioLicenciadoLocalStorage = UsuarioLocalStorageService.obter();
      const temLicencaAtiva = usuarioLicenciadoLocalStorage?.temLicencaFortesPessoal
        || usuarioLicenciadoLocalStorage?.temLicencaFortesRh
        || usuarioLicenciadoLocalStorage?.temLicencaFortesFinanceiro;

      if (temLicencaAtiva
        && usuarioLicenciadoLocalStorage
        && usuariosLicenciados.some(u => u.licenciadoCnpj === usuarioLicenciadoLocalStorage.licenciadoCnpj)) {

        setSessao(usuarioLicenciadoLocalStorage);
      } else {
        navigate('/selecionar-licenciado');
      }
    }
  }, [sessao, navigate, usuariosLicenciados]);

  useEffect(() => {
    (async () => {
      if (!estaAtualizando && intervalAtualizando) {
        clearInterval(intervalAtualizando);
        setIntervalAtualizando(undefined);
      }
      if (sessao && !intervalAtualizando && estaAtualizando) {
        setAmbiente({ espacoDeTrabalhoId: "", paineis: [] });

        const output = await AmbienteService.obterAmbiente()
        setAtualizando(!output.processado);
        setAmbiente(output as Ambiente);

        if (!output.processado) {
          setIntervalAtualizando(
            setInterval(async () => {
              const output = await AmbienteService.obterAmbiente()
              setAtualizando(!output.processado);
              setAmbiente(output as Ambiente);
            }, 3000)
          )
        }
      }
    })();
  }, [estaAtualizando, intervalAtualizando, sessao])

  const processaBloqueioPorDisponibilidade = useCallback(async () => {

    const userTimezone = DateTime.local().zoneName;
    const timestampAtual = await TimestampService.obterTimestamp();
    const dataAtual = DateTime.fromMillis(Number(timestampAtual), { zone: "utc" });
    const servidorBiTimeZoneId = "America/Fortaleza";
    const dataServidorBI = dataAtual.setZone(servidorBiTimeZoneId);
    
    const ultimoHorario = dataServidorBI.weekday === Sabado
      ? HoraFinalDisponibilidadeSabado
      : HoraFinalDisponibilidadeDiaUtil;

    const dataInicial = dataServidorBI.set({
      hour: HoraInicioDisponibilidade,
      minute: MinutoOuSegundoZero,
      second: MinutoOuSegundoZero
    });

    const dataFinal = dataServidorBI.set({
      hour: ultimoHorario,
      minute: MinutoFinalDisponibilidade,
      second: MinutoOuSegundoZero
    });

    const dataFinalSabado = dataServidorBI.set({
      hour: HoraFinalDisponibilidadeSabado,
      minute: MinutoOuSegundoZero,
      second: MinutoOuSegundoZero
    });

    setHorariosDeFuncionamento({
      horaInicio: dataInicial.setZone(userTimezone).hour,
      horaFimDiaUtil: dataFinal.setZone(userTimezone).hour,
      horaFimSabado: dataFinalSabado.setZone(userTimezone).hour
    });

    if (dataServidorBI.weekday === Domingo || dataServidorBI < dataInicial || dataFinal < dataServidorBI) {
      setBloqueio(true);
      setExibirCronometro(false);
      return;
    }

    const dataCronometro = dataFinal.minus({ minutes: MinutosAntesParaCronometro });

    if (dataCronometro <= dataServidorBI && dataServidorBI < dataFinal) {
      setExibirCronometro(true);
    }

    const dataProximoEvento = dataServidorBI < dataCronometro ? dataCronometro : dataFinal;
    const tempoParaIntervalo = Math.abs(dataProximoEvento.diff(dataServidorBI).milliseconds);

    setTempoRestante(tempoParaIntervalo);
    setTimeout(processaBloqueioPorDisponibilidade, tempoParaIntervalo);
  }, []);

  useEffect(() => {
    processaBloqueioPorDisponibilidade();
  }, [processaBloqueioPorDisponibilidade]);

  return (
    <SessaoUsuarioContext.Provider value={{ usuariosLicenciados, sessao, setSessao }}>
      <AppContext.Provider value={{ estaAtualizando, setAtualizando, ambiente, setAmbiente, feedback: useFeedback() }}>
        <div className="app">
          <header className="app-header">
            <div className="header-left">
              <div className="hamburger-icon">
                <Menu />
              </div>
              <div className="app-title">
                {
                  usuariosLicenciados && sessao
                    ? <Link to='/'> <img className="app-logo" src={logoImg} alt="Fortes Business Inteligence" /></Link>
                    : <img className="app-logo" src={logoImg} alt="Fortes Business Inteligence" />
                }
              </div>
            </div>
            {
              sessao && <div className="header-right">
                <MenuMovidesk id='menu-movidesk' />
                <UsuarioLogado />
                <button onClick={signOutCallback}><img src={signOutImg} width={25} alt="" /></button>
              </div>
            }
          </header>
          <div onClick={e => toggleMenu(isOpen)}>
            {sessao && estaAtualizando
              && <section className='app-info'>
                <div className='app-info-loading'>
                  <span>Seu ambiente está sendo atualizado... </span>
                  <Spinner size={14} />
                </div>
              </section>}
            {sessao && !estaAtualizando && exibirCronometro
              && <section className='app-info'>
                <div className='app-alert-loading'>
                  <span>Seu sistema será bockeado em instantes... </span>
                  <ContagemRegressiva aoZerarContagem={atualizaPagina} tempoRestanteMs={tempoRestante!} />
                </div>
              </section>}
            {sessao && !estaAtualizando && ocorreuErro
              && <section className='app-info'>
                <div className='app-error-loading'>
                  <span>Não conseguimos processar seu último envio de dados. Entre em contato com nosso suporte antes de tentar novamente, isso nos ajudará a identificar e resolver o problema. </span>
                </div>
              </section>}
            <section className='app-content'>
              <Feedback feedbacks={feedbacks} remover={removerFeedback} />
              <Routes>
                <Route path="/" element={
                  <ProtectedRoute horario={horariosDeFuncionamento} status={bloqueio}>
                    <Home />
                  </ProtectedRoute>}
                />
                <Route path="/modulo/:modulo" element={
                  <ProtectedRoute horario={horariosDeFuncionamento} status={bloqueio}>
                    <ListaDePaineis />
                  </ProtectedRoute>}
                />
                <Route path="/selecionar-licenciado" element={<SelecaoDeLicenciado />} />
                <Route path="/usuario" element={<Usuario />} />
                <Route path="/usuario/novo" element={<UsuarioCriacao />} />
                <Route path="/empresa" element={<Empresa />} />
                <Route path="/empresa/novo" element={<EmpresaCriacao />} />
                <Route path="/faturamento" element={
                  <ProtectedRoute horario={horariosDeFuncionamento} status={bloqueio}>
                    <Faturamento />
                  </ProtectedRoute>}
                />
                <Route path="/atualizar" element={
                  <ProtectedRoute horario={horariosDeFuncionamento} status={bloqueio}>
                    <AtualizarDados />
                  </ProtectedRoute>}
                />
                <Route path="/historico" element={<Historico />} />
                <Route path="/ticket" element={<Ticket />} />
                <Route path="/ticket/novo" element={<TicketNovo />} />
                <Route path="/painel/:modulo/:parametroNomePainel" element={<Painel />} />
              </Routes>
            </section>
          </div>
        </div>
      </AppContext.Provider>
    </SessaoUsuarioContext.Provider>
  );
}

export default App;

const atualizaPagina = () => window.location.reload();
