import './notificacao-indisponibilidade.css';

interface NotificaIndisponibilidadeProps {
  horarioInicio : number;
  horarioFimDiaUtil : number;
  horarioFimSabado : number;
}
const NotificaIndisponibilidade = ({horarioInicio, horarioFimDiaUtil, horarioFimSabado } : NotificaIndisponibilidadeProps) => {
  return (
    <>
      <div className='notificacao'>
        <h4>
          O Fortes BI fica disponível para uso de segunda a sábado nos seguintes horários:<br /><br />
          De segunda a sexta-feira, das {horarioInicio}h às {horarioFimDiaUtil}h.<br />
          Aos sábados, das {horarioInicio}h às {horarioFimSabado}h<br />
          Retornando na segunda-feira.<br /><br />
          Contamos com a sua compreensão.<br />
        </h4>
      </div>
    </>
  );
}
export default NotificaIndisponibilidade;