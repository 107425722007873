
import { ReactNode } from 'react';
import NotificacaoIndisponibilidade from '../notificacao-indisponibilidade/notificacao-indisponibilidade';

interface ProtectedRouteProps {
  status: boolean,
  children: ReactNode,
  horario:{
    horaInicio: number,
    horaFimDiaUtil: number,
    horaFimSabado:number
  }
}
const ProtectedRoute = ({ status, children, horario }: ProtectedRouteProps) : JSX.Element => {
  return (
    <>
      {status ? (
        <NotificacaoIndisponibilidade horarioInicio={horario?.horaInicio} horarioFimDiaUtil={horario?.horaFimDiaUtil} horarioFimSabado={horario?.horaFimSabado}/>
      ) : (
        children
      )}
    </>
  );
};

export default ProtectedRoute;