import { useEffect, useState } from "react";
import { DivideMiliSegundos, DivideSegundosTotais, PercentualSegundosTotais, SegundosDoCronometro } from "../../helpers/constantes/tempo";

interface TempoRestante {
  minutos: number;
  segundos: number;
}

interface ContagemRegressivaProps {
  aoZerarContagem: () => void;
  tempoRestanteMs: number
}

const ContagemRegressiva = ({ aoZerarContagem = () => { }, tempoRestanteMs = 0 }: ContagemRegressivaProps) => {
  const [tempoRestante, setTempoRestante] = useState<TempoRestante>(calcularTempoRestante(tempoRestanteMs));

  useEffect(() => {
    const interval = setInterval(() => {
      setTempoRestante(prev => {
        if (prev.minutos === 0 && prev.segundos === 0) {
          aoZerarContagem();
          clearInterval(interval);
          return prev;
        }

        const novoSegundos = prev.segundos - 1;
        if (novoSegundos < 0) {
          return {
            minutos: prev.minutos - 1,
            segundos: SegundosDoCronometro
          };
        }
        return { ...prev, segundos: novoSegundos };
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [aoZerarContagem]);

  return (
    <>
      <span>{"00:" + formatNumber(tempoRestante.minutos)}:{formatNumber(tempoRestante.segundos)}</span>
    </>
  );
}

export default ContagemRegressiva;

const formatNumber = (num: number): string => String(num).padStart(2, '0');

const calcularTempoRestante = (ms: number): TempoRestante => {
  const segundosTotais = Math.floor(ms / DivideMiliSegundos);
  const minutos = Math.floor(segundosTotais / DivideSegundosTotais);
  const segundos = segundosTotais % PercentualSegundosTotais;
  return { minutos, segundos };
};
